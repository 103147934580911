// app/javascript/controllers/tap_checkbox_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["beerCheckbox", "guestCheckbox"]

  connect() {
    // Initial check to ensure guest taps are only checked if beer is selected
    this.guestCheckboxTargets.forEach((guestCheckbox, index) => {
      if (guestCheckbox.checked && !this.beerCheckboxTargets[index].checked) {
        guestCheckbox.checked = false
      }
    })
  }

  toggleBeer(event) {
    const checkbox = event.target
    const index = this.beerCheckboxTargets.indexOf(checkbox)
    const guestCheckbox = this.guestCheckboxTargets[index]

    if (!checkbox.checked) {
      guestCheckbox.checked = false
    }
  }

  toggleGuest(event) {
    const guestCheckbox = event.target
    const index = this.guestCheckboxTargets.indexOf(guestCheckbox)
    const beerCheckbox = this.beerCheckboxTargets[index]

    if (guestCheckbox.checked && !beerCheckbox.checked) {
      beerCheckbox.checked = true
    }
  }
}