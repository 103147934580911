import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table"
export default class extends Controller {
  connect() {

    const rows = this.element.getElementsByTagName('li')

    if (this.element.firstChild.classList.contains('header')){  }

    else if ( rows.length < 1 ) { }

    else {

      const header = document.createElement('li');
      header.classList.add('header');

      Array.from(rows[0].children).forEach((element) => {
        header.append(this.createElement(element))
      });

      this.element.prepend(header);

    }
  }

  createElement(element) {
    let newSpan = document.createElement('span');
    let newTag = document.createTextNode(element.dataset.field);

    newSpan.classList.add(...element.classList)
    newSpan.appendChild(newTag)
    return newSpan
  }
}
